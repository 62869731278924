// extracted by mini-css-extract-plugin
export var root = "Video-module--root--3tJGg";
export var main = "Video-module--main--2H7s9";
export var image = "Video-module--image--22T-9";
export var video = "Video-module--video--2aLtj";
export var vimeo = "Video-module--vimeo--2j0Dq";
export var vimeoPlayer = "Video-module--vimeoPlayer--10sOM";
export var maskLayer = "Video-module--mask-layer--2H4Z0";
export var icon = "Video-module--icon--2WRhP";
export var visible = "Video-module--visible--bbZu6";
export var smallIcon = "Video-module--smallIcon--3Utoz";
export var controls = "Video-module--controls--2BIro";
export var show = "Video-module--show--1N-2-";
export var progressBar = "Video-module--progressBar--2_lnR";
export var detailed = "Video-module--detailed--1_2Ws";
export var hidden = "Video-module--hidden--Py2DY";
export var interactionBar = "Video-module--interactionBar--2P_Y2";
export var marker = "Video-module--marker--2OHM3";
export var dragged = "Video-module--dragged--28WYH";
export var expanded = "Video-module--expanded--3Hw-N";
export var playProgress = "Video-module--playProgress--1BKZt";
export var time = "Video-module--time--k_mDT";
export var caption = "Video-module--caption--FI2AV";