import React from 'react';
import Layout from '../../layouts/Project';
import ProjectIntro from '../../content/ProjectIntro';
import CapsAndTech from '../../content/CapsAndTech';
import Text from '../../content/Text';
import Caption from '../../content/ImageCaption';
import { StaticImage as Image } from 'gatsby-plugin-image';
import Images from '../../content/Images';
import Video from '../../content/Video';
import Press from '../../content/Press';
import Credits from '../../content/Credits';

export const frontmatter = {
  title: 'AR Catwalk Fashion Week NY',
  projectType: 'Experiences',
  description:
    'The AR Catwalk is a synchronised multiplayer experience that enhanced the live runway during New York Fashion Week, 2016.',
  client: ['Ground Truth (Previously xAd)'],
  release: '2016',
  capabilities: [
    'Experience Design',
    'Software Engineering',
    'Real-Time',
    'AR, VR',
    '3D Animation',
    'CG & VFX'
  ],
  technology: [
    'Unity',
    'OpenGL',
    'Augmented Reality',
    'Multiplayer ',
    'Network Engineering',
    'Web Sockets'
  ],
  credits: {
    Client: 'Ground Truth (Previously xAd)',
    'Creative Director': 'Igal Nassima',
    'CG / VFX': 'Superbright',
    'Installation Design / Fabrication': 'Superbright',
    Engineering: 'Superbright'
  },
  order: 4
};

const clientLogos = [
  <Image
    src="../../images/work/ar-fashion-week-ny/GroundTruth-Logo.jpg"
    alt="xAd"
    placeholder="none"
  />
];
const Page = () => (
  <Layout title={frontmatter.title}>
    <ProjectIntro frontmatter={frontmatter} clientLogos={clientLogos} />
    <Images>
      <Image
        src="../../images/work/ar-fashion-week-ny/ar-catwalk.jpg"
        alt="Image of AR fashion show"
      />
    </Images>
    <CapsAndTech frontmatter={frontmatter} />
    <Text title="Opportunity" split={2} large>
      <p>
        Superbright was commissioned by xAd, a location based marketing company
        (now Ground Truth) to create a custom catwalk experience that looked to
        answer the question “What is the future of fashion?”
      </p>
      <p>
        Fashion shows have been an avenue for utilizing interactive technology
        by designers such as Alexander Mcqueen or Huseyin Caglayan often
        creating a commentary on contemporary culture.
      </p>
      <p>
        Using mobile phones to record peoples experiences had become been second
        nature by 2016 so this was a chance to explore how we could take it one
        step further. Adding a layer of visual augmentation to enhance the
        fashion show, that synchronised live with the models on the catwalk
        provided us an opportunity to really explore the idea of “Future of
        Fashion”.
      </p>
    </Text>
    <Video vimeoId="188906999" vimeoCode="f6c0b9c24a" />

    <Text title="Solution">
      <p>
        Superbright developed a custom stage that merged a proprietary AR marker
        system with an integrated LED screen that when combined, generated
        volumetric data of the catwalk and surrounding area. This allowed us to
        treat the runway as a three dimensional canvas.
      </p>
      <p>
        A custom server streamed timecode to all connected devices within the
        venue to sync everyone's mobile device to provide a simultaneous shared
        experience that meant the augmented content across the LED and audience
        phones were perfectly timed across the show.{' '}
      </p>
    </Text>

    <Images>
      <Image
        src="../../images/work/ar-fashion-week-ny/Left_2.jpg"
        alt="AR Catwalk Fashion Week NY"
      />
      <Image
        src="../../images/work/ar-fashion-week-ny/Right_1.jpg"
        alt="AR Catwalk Fashion Week NY"
      />
      <Image
        src="../../images/work/ar-fashion-week-ny/Left_3.jpg"
        alt="AR Catwalk Fashion Week NY"
      />
    </Images>

    <Text title="Output" large>
      <p>
        Superbright worked with fashion designer Lindsay Frimodt to develop the
        visual language and direction for the augmented creative in order for it
        to complement her garments.
      </p>
      <p>
        The final experience was a live physical 15 minute fashion show that
        played out within a ‘virtual’ three dimensional space, further enhanced
        by the personal AR experience that each audience member enjoyed on their
        personal device.
      </p>
      <p>
        The deployment of LED screens and contextual content was an early
        adopter of Virtual Production technology that we are becoming more
        prevalent in game and film production.
      </p>
    </Text>

    <Press
      quotes={[
        {
          quote:
            'Creative technology studio Superbright, working with xAd, stunned a 200+ audience with a fashion show that was truly one of a kind',
          heading: 'Forbes',
          url: 'https://www.forbes.com/sites/kevinanderton/2017/02/04/fashion-show-uses-augmented-reality-to-captivate-audience-infographic/?sh=4f06aa8a7ce5',
          date: 'February, 2017'
        }
      ]}
    />
    <Credits credits={frontmatter.credits} />
  </Layout>
);

export default Page;
