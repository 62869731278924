import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import classnames from 'classnames';

import { SidesContainer } from '../components/Container';
import { formatTime } from '../utils/index';
import { useIntersect } from '../utils/hooks';

import PlayIcon from '../assets/icon_play.svg';
import VolumeIcon from '../assets/icon_volume.svg';
import MuteIcon from '../assets/icon_mute_volume.svg';

import * as css from './Video.module.css';
import * as grid from '../styles/grid.module.css';

const VideoContainer = props => {
  const { src, vimeoId, caption } = props;
  return (
    <SidesContainer tag="figure" bottomLine>
      {vimeoId ? <Vimeo {...props} /> : <Video {...props} />}
      <figcaption className={css.caption}>
        <p>
          {src && caption}
          {!vimeoId && !src && 'Video not found'}
        </p>
      </figcaption>
    </SidesContainer>
  );
};

export const Vimeo = ({ vimeoId, vimeoCode, autoplay }) => {
  return (
    <div className={css.vimeo}>
      <iframe
        className={css.vimeoPlayer}
        src={`//player.vimeo.com/video/${vimeoId}?${
          vimeoCode && `h=${vimeoCode}&`
        }color=ffffff&autoplay=${
          autoplay ? '1' : '0'
        }&loop=1&title=0&byline=0&portrait=0`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
};

const Video = ({ src, poster, ratio = 1.777 }) => {
  const [muted, setMuted] = useState(true);
  const [paused, setPaused] = useState(true);
  const [progressBar, setProgressBar] = useState(false);

  const [ref, appearedOnScreen] = useIntersect({ threshold: 0.75 });

  const video = useRef(null);

  useEffect(() => {
    if (appearedOnScreen) {
      setPaused(false);
    }
  }, [appearedOnScreen]);

  useEffect(() => {
    if (src && !paused) {
      video.current.play();
    } else if (src) {
      video.current.pause();
    }
  }, [src, paused, video]);

  const handleOnClick = e => {
    e.stopPropagation();
    setPaused(paused => !paused);
  };

  const toggleVolume = e => {
    e.stopPropagation();
    setMuted(muted => !muted);
  };

  const toggleProgressBar = value => {
    setProgressBar(value);
  };

  return (
    <div className={classnames(grid.col, css.root)}>
      {src && (
        <div
          className={css.main}
          style={{ padding: `0 0 ${100 / ratio}%` }}
          onMouseEnter={() => toggleProgressBar(true)}
          onMouseLeave={() => toggleProgressBar(false)}
          ref={ref}
          role="toolbar"
        >
          <video
            className={css.video}
            loop
            ref={video}
            controls={false}
            playsInline
            muted={muted}
            src={src}
            poster={poster}
          >
            Could not load video
          </video>
          <PlayIcon
            className={classnames(css.icon, {
              [css.visible]: paused
            })}
          />
          <div
            className={classnames(css.maskLayer, {
              [css.visible]: paused
            })}
            onClick={handleOnClick}
            onKeyDown={e => e.key === 'Enter' && handleOnClick(e)}
            role="button"
            tabIndex={0}
            aria-label="pause button"
          ></div>
          <VideoControls
            video={video}
            progressBar={progressBar}
            muted={muted}
            toggleVolume={toggleVolume}
          />
        </div>
      )}
    </div>
  );
};

const VideoControls = ({ video, progressBar, muted, toggleVolume }) => {
  const [progressBarDetail, setProgressBarDetail] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [isDragged, setIsDragged] = useState(false);

  const [animation, setAnimation] = useSpring(() => ({
    currentTime: 0,
    immediate: true
  }));

  const { buffered } = video.current ? video.current : 0;
  const duration = video.current ? video.current.duration : 0;

  const timeRanges =
    buffered && buffered.length > 0
      ? [...Array(buffered.length).keys()].map((_, i) => ({
          start: buffered.start(i),
          end: buffered.end(i),
          width: buffered.end(i) - buffered.start(i)
        }))
      : [];

  useEffect(() => {
    if (video.current && setAnimation) {
      const timer = setInterval(() => {
        setAnimation({ currentTime: video.current.currentTime });
      }, 50);
      return () => {
        clearInterval(timer);
      };
    }
  }, [video, setAnimation]);

  const toggleProgressBarDetail = value => {
    setProgressBarDetail(value);
  };

  const startDrag = e => {
    e.stopPropagation();
    setStartTime(new Date().getTime());
    setIsDragged(true);
  };

  const timeChange = e => {
    e.stopPropagation();
    if (isDragged && progressBarDetail) {
      const { clientX, target } = e.touches ? e.touches[0] : e;
      const { left, width } = target.getBoundingClientRect();
      const x = clientX - left;
      const position = x / width;
      video.current.currentTime = position * duration;
    }
  };

  const endDrag = e => {
    e.stopPropagation();
    if (
      isDragged &&
      startTime - new Date().getTime() < 200 &&
      progressBarDetail
    ) {
      const { clientX } = e;
      const { left, width } = e.target.getBoundingClientRect();
      const x = clientX - left;
      const position = x / width;
      video.current.currentTime = position * duration;
    }
    if (isDragged) setIsDragged(false);
  };

  return (
    <div
      className={classnames(css.controls, { [css.show]: progressBar })}
      onMouseEnter={() => toggleProgressBarDetail(true)}
      onMouseLeave={() => toggleProgressBarDetail(false)}
      role="toolbar"
    >
      {muted ? (
        <MuteIcon className={css.smallIcon} onClick={toggleVolume} />
      ) : (
        <VolumeIcon
          className={css.smallIcon}
          onClick={toggleVolume}
          onMouseUp={e => e.stopPropagation()}
        />
      )}
      <div
        className={classnames(css.time, {
          [css.hidden]: !progressBar
        })}
      >
        <animated.span>
          {animation.currentTime.interpolate(t => formatTime(Math.floor(t)))}
        </animated.span>
      </div>
      <div
        className={classnames(
          css.progressBar,
          {
            [css.hidden]: !progressBar
          },
          { [css.detailed]: progressBarDetail }
        )}
      >
        {timeRanges.map((e, i) => (
          <div
            className={css.playProgress}
            key={`range-${i}`}
            style={{
              left: `calc(${e.start}/${duration}*100%)`,
              width: `calc(${e.width}/${duration}*100%)`
            }}
          />
        ))}
        <animated.div
          className={classnames(
            css.marker,
            {
              [css.dragged]: isDragged
            },
            { [css.expanded]: progressBarDetail }
          )}
          style={{
            left: animation.currentTime.interpolate(
              t => `calc(${t}/${duration}*100%)`
            )
          }}
        />
        <div
          className={css.interactionBar}
          onMouseDown={startDrag}
          onTouchStart={startDrag}
          onMouseMove={timeChange}
          onTouchMove={timeChange}
          onMouseUp={endDrag}
          role="progressbar"
          aria-label="video progressbar"
          tabIndex={0}
        />
      </div>
      <div
        className={classnames(css.time, {
          [css.hidden]: !progressBar
        })}
      >
        <span>{(duration && formatTime(Math.floor(duration))) || ''}</span>
      </div>
    </div>
  );
};

export default VideoContainer;
